<template>
    <div>
        <form
            autocomplete="off"
            class="p-4 p-md-5 border rounded-3 bg-light"
            @submit.stop.prevent="onSubmit"
        >
            <div class="form-group mb-3 text-center">
                <div
                    class="form-check form-check-inline mb-3"
                    v-for="(option, index) in Genders"
                    :key="option"
                >
                    <input
                        class="form-check-input"
                        type="radio"
                        name="gender"
                        :id="option"
                        :value="option"
                        :data-test="'input_option_' + index"
                        v-validate="'required'"
                        v-model="form.Gender"
                        :class="{
                            'is-invalid': submitted && veeErrors.has('gender'),
                        }"
                    />
                    <label class="form-check-label" :for="option">
                        {{ $et("salutation", option) }}</label
                    >
                </div>
                <div>
                    <span
                        data-test="invalid_gender"
                        v-if="submitted && veeErrors.has('gender')"
                        class="is-invalid"
                    >
                        {{
                            $et(
                                "landingpage_component",
                                "error_required_gender"
                            )
                        }}
                    </span>
                </div>
            </div>

            <div class="form-group mb-3">
                <input
                    type="text"
                    class="form-control"
                    maxlength="64"
                    v-validate="'max:64'"
                    v-model.trim="form.Name"
                    id="name"
                    name="name"
                    data-test="input_name"
                    :placeholder="$et('landingpage_component', 'name')"
                    :class="{
                        'is-invalid': submitted && veeErrors.has('name'),
                    }"
                />
                <div>
                    <span
                        data-test="invalid_name"
                        v-if="submitted && veeErrors.has('name')"
                        class="is-invalid"
                    >
                        {{
                            $et(
                                "landingpage_component",
                                "error_max_name_character"
                            )
                        }}
                    </span>
                </div>
            </div>

            <div class="form-group mb-3">
                <input
                    class="form-control"
                    maxlength="64"
                    v-validate="'email|max:64'"
                    v-model.trim="form.Email"
                    id="email"
                    name="email"
                    data-test="input_email"
                    :placeholder="$et('landingpage_component', 'email')"
                    :class="{
                        'is-invalid': submitted && veeErrors.has('email'),
                    }"
                />
                <div>
                    <span
                        data-test="invalid_email"
                        v-if="submitted && veeErrors.has('email')"
                        class="is-invalid"
                    >
                        {{
                            $et(
                                "landingpage_component",
                                "error_email"
                            )
                        }}
                    </span>
                </div>
            </div>

            <div class="text-center mb-3">
                <base-button
                    data-test="form_submit"
                    size="lg"
                    type="submit"
                    id="submit"
                    icon="bi bi-play"
                >
                    {{ $et("landingpage_component", "submit_btn_name") }}
                </base-button>
            </div>

            <hr class="my-4" />

            <small class="text-muted">{{
                $et("landingpage_component", "note")
            }}</small>
        </form>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "landingPage",
    data() {
        return {
            form: {
                Name: "",
                Email: "",
                Gender: "",
            },
            submitted: false,
        };
    },

    computed: {
        ...mapGetters("settings", ["Genders"]),
    },

    methods: {
        ...mapActions("session", ["setUserDetails"]),

        onSubmit() {
            this.submitted = true;

            this.$validator.validateAll().then((result) => {
                if (!result) {
                    return;
                }
                this.setUserDetails({
                    Name: this.$h(this.form.Name),
                    Email: this.$h(this.form.Email),
                    Gender: this.$h(this.form.Gender),
                    Score: 0,
                });
                this.$router.push({ name: "NoticePage" });
            });
        },
    },
};
</script>