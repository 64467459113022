<template>
    <div class="align-self-center">
        <div class="text-center">
            <p class="display-3 fw-bold lh-2 ">
                {{ $et("landingpage_component", "headline") }}
            </p>
            <p class="display-6 py-2">
                {{ $et("landingpage_component", "description") }}
                <span class="fw-bold">
                    {{ $et("landingpage_component", "test-now") }}</span
                >
            </p>
        </div>
        <div class="mx-auto">
            <landing-form></landing-form>
        </div>
    </div>
</template>
<script>
import LandingForm from "@/components/LandingForm";
export default {
    name: "landingPage",
    components: { LandingForm },
};
</script>