var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{staticClass:"p-4 p-md-5 border rounded-3 bg-light",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group mb-3 text-center"},[_vm._l((_vm.Genders),function(option,index){return _c('div',{key:option,staticClass:"form-check form-check-inline mb-3"},[_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.form.Gender),expression:"form.Gender"}],staticClass:"form-check-input",class:{
                        'is-invalid': _vm.submitted && _vm.veeErrors.has('gender'),
                    },attrs:{"type":"radio","name":"gender","id":option,"data-test":'input_option_' + index},domProps:{"value":option,"checked":_vm._q(_vm.form.Gender,option)},on:{"change":function($event){return _vm.$set(_vm.form, "Gender", option)}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":option}},[_vm._v(" "+_vm._s(_vm.$et("salutation", option)))])])}),_c('div',[(_vm.submitted && _vm.veeErrors.has('gender'))?_c('span',{staticClass:"is-invalid",attrs:{"data-test":"invalid_gender"}},[_vm._v(" "+_vm._s(_vm.$et( "landingpage_component", "error_required_gender" ))+" ")]):_vm._e()])],2),_c('div',{staticClass:"form-group mb-3"},[_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('max:64'),expression:"'max:64'"},{name:"model",rawName:"v-model.trim",value:(_vm.form.Name),expression:"form.Name",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.veeErrors.has('name'),
                },attrs:{"type":"text","maxlength":"64","id":"name","name":"name","data-test":"input_name","placeholder":_vm.$et('landingpage_component', 'name')},domProps:{"value":(_vm.form.Name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "Name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',[(_vm.submitted && _vm.veeErrors.has('name'))?_c('span',{staticClass:"is-invalid",attrs:{"data-test":"invalid_name"}},[_vm._v(" "+_vm._s(_vm.$et( "landingpage_component", "error_max_name_character" ))+" ")]):_vm._e()])]),_c('div',{staticClass:"form-group mb-3"},[_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('email|max:64'),expression:"'email|max:64'"},{name:"model",rawName:"v-model.trim",value:(_vm.form.Email),expression:"form.Email",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.veeErrors.has('email'),
                },attrs:{"maxlength":"64","id":"email","name":"email","data-test":"input_email","placeholder":_vm.$et('landingpage_component', 'email')},domProps:{"value":(_vm.form.Email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "Email", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',[(_vm.submitted && _vm.veeErrors.has('email'))?_c('span',{staticClass:"is-invalid",attrs:{"data-test":"invalid_email"}},[_vm._v(" "+_vm._s(_vm.$et( "landingpage_component", "error_email" ))+" ")]):_vm._e()])]),_c('div',{staticClass:"text-center mb-3"},[_c('base-button',{attrs:{"data-test":"form_submit","size":"lg","type":"submit","id":"submit","icon":"bi bi-play"}},[_vm._v(" "+_vm._s(_vm.$et("landingpage_component", "submit_btn_name"))+" ")])],1),_c('hr',{staticClass:"my-4"}),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$et("landingpage_component", "note")))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }